import React from 'react';
import PropTypes from 'prop-types';
import tpl from './templates/interactive.jsx';
import localizationRU from '../services/l10n/ru.json';
import localizationUA from '../services/l10n/ua.json';
import {View, l10n, iSessionStorage} from 'MWC';
import {createBrowserHistory} from 'history';
import {set} from '../config';
import connectResponsive from './hoc/responsive.c';
import {LANG} from '../services/l10n';
import {default as cookie} from '../helpers/vendor/cookie';
import * as moment from 'moment';
import {PLATINUM_CARD} from '../auxiliary-components/Breadcrumbs/collection/breadcrumbs.config';

const URL_GET_PARAMS = {
    SINGLE: 'single'
};

class Application extends View {
    templates = {
        [this.uiStates.INITIAL]: <div />,
        [this.uiStates.INTERACTIVE]: tpl,
        [this.uiStates.ERROR]: tpl,
        [this.uiStates.STANDBY]: tpl
    };

    constructor(props) {
        super(props);
        this.state = {
            ui: this.uiStates.INITIAL,
            // lang: iSessionStorage.get('lang') || LANG.UA,
            lang: LANG.UA,
            single: this.renderTopAndBottom(),
            showModal: this.isNeedShow(),
            isBlackTheme: false,
            isApple: this.checkForApple()
        };

        this.history = createBrowserHistory();
        set('LANG', this.state.lang);
        l10n.setDefaultLocale(localizationUA);
        // l10n.setLocale(this.state.lang === LANG.UA ? localizationUA : localizationRU);
        l10n.setLocale(localizationUA);
        this.closeModal = this.closeModal.bind(this);
        this.setBlackTheme = this.setBlackTheme.bind(this);
        return this;
    }

    componentWillMount() {
        this.unlisten = this.history.listen((location, action) => {
            this.setBlackTheme(location.pathname === PLATINUM_CARD.link);
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    checkForApple() {
        const platform = navigator.platform;
        const appleReqExp = /(Mac|iPhone|iPod|iPad)/i;
        return appleReqExp.test(platform);
    }

    closeModal() {
        cookie('show', true, {
            expires: moment()
                .add(1, 'days')
                .hour(0)
                .minutes(0)
                .seconds(0)
                .toDate()
        });
        this.setState({showModal: false});
    }

    isNeedShow() {
        const path = location.pathname;
        const isNeedHide =
            path.includes('business-menu') ||
            path.includes('business-card') ||
            path.includes('mobile-menu-qr') ||
            path.includes('thankyou/a24') ||
            path.includes('atb-market') ||
            path.includes('branches');
        return !(isNeedHide || cookie('show'));
    }

    // setLang(lang) {
    //     l10n.setLocale(lang === LANG.UA ? localizationUA : localizationRU);
    //     iSessionStorage.set('lang', lang);
    //     set('LANG', lang);
    //     return this.setState({lang});
    // }

    setBlackTheme(isBlack) {
        this.setState({isBlackTheme: isBlack});
    }

    setLang(lang) {
        l10n.setLocale(localizationUA);
        lang === LANG.UA ? this.history.goBack() : this.navigate('/offrus');
        iSessionStorage.set('lang', lang);
        set('LANG', lang);
        return this.setState({lang});
    }

    getChildContext() {
        return {
            app: this,
            history: this.history
        };
    }

    navigate(path = '/', e = {}) {
        this.sendGA(path, e);
        if (e.nativeEvent) {
            e.preventDefault();
        }

        if (!cookie('show')) {
            this.closeModal();
        }

        return this.history.push(path + window.location.search);
    }

    sendGA(path, e) {
        const data = {
            event: 'click',
            data: e
        };
        if (window.ga) {
            window.ga('set', 'page', path);
            window.ga('send', 'pageview', location.pathname);
            window.dataLayer.push(data);
        }
    }

    renderTopAndBottom() {
        return Boolean(Number(new URLSearchParams(location.search).get(URL_GET_PARAMS.SINGLE)));
    }
    changeSingle(single) {
        this.setState({single});
    }

    static displayName = 'App';

    static childContextTypes = {
        app: PropTypes.object,
        history: PropTypes.object
    };
}

export default connectResponsive(Application);
